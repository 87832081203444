// COLORS
$primary-color: #01579b;
$secondary-color: #263238;

// $primary-color: #7f449d;
// $secondary-color: #793fc1;

$white-color: #fff;

$dark-grey: #333333;

$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;