#projects {
    min-height: 50vh;
    padding: 50px;
    display: flex;
    width: 100%;
    padding-bottom: 10%;

    .prj-title {
        text-align: center;
        margin-bottom: 80px;
    }

    .prj-wrapper {
        margin-top: 25px;
    }

    .project-row {
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 150px;
        margin-right: 150px;
        align-content: center;

        @include respond(tab-land){
            margin-left: 100px;
            margin-right: 100px;
        }

        @include respond(tab-port)
        {
            margin-left: 0px;
            margin-right: 0px;
        }

        @include respond(phone) {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .project-text {
        margin-top: auto;
        margin-bottom: auto;
    }

    .prj-image {
        max-width: 657px;
        height: auto;
        border-radius: 10px;

        -webkit-box-shadow: 0px 0px 10px -5px #000000; 
box-shadow: 0px 0px 10px -4px #000000;

        @include respond(tab-land)
        {
            max-width: 100%;
            height: auto;
        }

        @include respond(phone-xs) {
            max-width: 0px;
            height: auto;
        }

        @include respond(phone) {
            max-width: 0px;
            height: auto;
        }

        
    }

    .prj-desc-title {
        font-size: 28px;
        font-weight: 600;
        @include respond(tab-land)
        {
            font-size: 24px;
            font-weight: 600;
        }
        

        @include respond(phone) {
        }


        @include respond(phone-xs) {
        }

        
    }

    .prj-desc-sub {
        @include respond(phone-xs) {
        }

        @include respond(phone) {
        }

        @include respond(tab-land)
        {
        }
    }

    .prj-desc-desc {
        @include respond(tab-land) {
            font-size: 20px;
        }

        @include respond(phone) {
            font-size: 18px;
        }

        @include respond(phone-xs)
        {
            font-size: 18px;
        }
    }

    .android_btn {
        position: relative;
        bottom: 8px;
    }
}